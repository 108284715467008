import React, { memo, useEffect, useState } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { green, red, yellow } from '@material-ui/core/colors';
import { WarrantyInformationDTO } from 'src/modules/generated/api';
import {
  colors,
  countryCodeToOption,
  damageTypes,
  equipments,
  getLabel,
  highlightLabels,
  promotions,
  sources,
  specialHighlights,
} from '../modules/labels';
import { COUNTRY_ORIGIN_FILTER_COUNTRIES, getIcon, IconType, fallbackIcon } from '../modules/data';

const options = {
  COLOR: colors,
  COUNTRY: COUNTRY_ORIGIN_FILTER_COUNTRIES,
  EQUIPMENT: equipments,
  DAMAGE_TYPE: damageTypes,
  VIRTUAL_SOURCE: sources,
  SPECIAL: specialHighlights,
  PROMOTION: promotions,
} as const;

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: spacing(3),
    height: 'auto',
    display: 'block',
    marginRight: 1,
    marginBottom: 1,
  },
  damage: {
    backgroundColor: '#f5c6cb',
  },
  green: {
    backgroundColor: green[200],
  },
  red: {
    backgroundColor: red[200],
  },
  warranty: {
    backgroundColor: green[200],
  },
  yellow: {
    backgroundColor: yellow[200],
  },
  warrantyTooltip: {
    fontSize: '0.75rem',
    whiteSpace: 'pre-line',
  }
}));

type HighlightIconProps = {
  type: Exclude<IconType, 'LANGUAGE'>;
  value?: string;
  label?: string;
  color?: 'red' | 'green' | undefined;
  titleSuffix?: string;
  warrantyInfo?: Array<WarrantyInformationDTO>;
};

const HighlightIcon = ({ type, value = '', label = '', color, titleSuffix = '', warrantyInfo }: HighlightIconProps) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [imgSrc, setImgSrc] = useState<string | undefined>();
  const onError = () => setImgSrc(fallbackIcon);

  // special handling to display all warranty infos in the Tooltip of the highlight icon
  function getWarrantyInfoText() {
    if (!warrantyInfo) {
      return '';
    }

    const warrantyInfoText = warrantyInfo.map((warranty): string => {
      const formatDate = (date?: string) => {
        if (!date) {
          return '-';
        }
        const [year, month, day] = date.split('-');
        return new Date(+year, +month - 1, +day).toLocaleDateString(i18n.language, {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      };

      return (
        `${t("car.equipments.helperText.description")}: ${warranty.description}\n` +
        `${t("car.equipments.helperText.maxMilage")}: ${warranty.maxMilage?.toLocaleString(i18n.language)} km\n` +
        `${t("car.equipments.helperText.startedOn")}: ${formatDate(warranty?.startDate)}\n` +
        `${t("car.equipments.helperText.endsOn")}: ${formatDate(warranty?.expirationDate)}\n`
      );
    }).join('\n') ?? "";
    return warrantyInfoText;
  }
  const warrantyInfoTooltip = getWarrantyInfoText()

  let renderLabel;
  const typeLabel = getLabel(highlightLabels, type);

  switch (type) {
    case IconType.ASSESSMENT:
      renderLabel = value === '2' ? t('common.statusReport') : typeLabel;
      break;
    case IconType.EXTERNAL_PORTAL:
      renderLabel = `${typeLabel}: ${value}`;
      break;
    case IconType.COUNTRY:
      renderLabel = `${typeLabel}: ${countryCodeToOption(value, i18n.language).label}`;
      break;
    case IconType.SPECIAL:
      renderLabel = `${getLabel(options[type], value)}`;
      break;
    case IconType.PROMOTION:
      renderLabel = `${getLabel(options[type], value)}`;
      break;
    case IconType.EQUIPMENT:
      if (value === 'WARRANTY_EXTENSION') {
        renderLabel = `${typeLabel}: ${label || getLabel(options[type], value)}\n\n${warrantyInfoTooltip}`
      } else {
        renderLabel = `${typeLabel}: ${label || getLabel(options[type], value)}`;
      }
      break;
    default:
      renderLabel = `${typeLabel}: ${label || getLabel(options[type], value)}`;
  }

  useEffect(() => {
    setImgSrc(getIcon(type, value));
  }, [type, value]);

  return (
    <Tooltip title={`${renderLabel} ${titleSuffix}`} classes={{ tooltip: value === "WARRANTY_EXTENSION" ? classes.warrantyTooltip : "" }}>
      <img
        className={clsx(
          classes.root,
          type === IconType.DAMAGE_TYPE && classes.damage,
          value === 'REFURBISHED' && classes.green,
          value === 'REPAIRED_DAMAGE' && classes.yellow,
          value === 'WARRANTY_EXTENSION' && color !== 'red' && classes.warranty,
          color === 'red' && classes.red,
          color === 'green' && classes.green,
        )}
        src={imgSrc}
        alt={renderLabel.toString()}
        onError={onError}
      />
    </Tooltip>
  );
};

export default memo(HighlightIcon);
