import React, { MouseEvent, ReactElement, ReactNode, Reducer, useReducer, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Avatar, Chip, CircularProgress, makeStyles, Paper, Popper, PopperProps } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { getLabel } from '../modules/labels';
import AutocompleteItem from './AutocompleteItem';
import { Option } from '../modules/i18n-helpers';
import { getIcon, IconType } from '../modules/data';

const useStyles = makeStyles((theme) => ({
  checkbox: { marginRight: theme.spacing(1), padding: 0 },
  chip: { marginTop: theme.spacing(0.25), marginBottom: theme.spacing(0.25) },
  iconImage: { display: 'inline-block', width: 20, height: 'auto', marginRight: theme.spacing(1) },
  notchedOutline: {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
  },
  selectAll: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    width: '95%',
    justifyContent: 'left',
    paddingTop: theme.spacing(1),
  },
  heightReduce: {
    paddingTop: 0,
    marginTop: 0,
    marginBottom: 0,
  },
}));

type FilterAutocompleteProps = {
  name: string;
  defaultValue?: string;
  label: string;
  iconType?: IconType;
  IconComponent?: ({ value }: { value: string }) => ReactElement;
  disableClearable?: boolean;
  multiple?: boolean;
  options: Readonly<Option[]>;
  isLoading?: boolean;
  highlightTextField?: boolean;
  onChipDelete?: (value: string) => void;
  selectAllOnDefault?: boolean;
  maxOptions?: boolean;
  groupBy?: (option: string) => string;
  customOnChange?: (value: string | string[] | null) => void;
  hideCheckbox?: boolean;
  className?: string;
};

type Action = { type: 'add'; suggestion: string } | { type: 'reset' };

const reducer: Reducer<Set<string>, Action> = (suggestions, action) => {
  switch (action.type) {
    case 'reset':
      return new Set();
    case 'add':
      return new Set(suggestions).add(action.suggestion);
    default:
      throw new Error();
  }
};

const maxLoad = 50;

const FilterAutocomplete = ({
  name,
  defaultValue,
  label,
  iconType,
  IconComponent,
  disableClearable = false,
  multiple = false,
  options,
  isLoading = false,
  highlightTextField,
  onChipDelete,
  maxOptions,
  groupBy,
  customOnChange,
  hideCheckbox = false,
  className
}: FilterAutocompleteProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [maxShown, setMaxShown] = useState(maxLoad);
  const optionValues = options.map(({ value }) => value);
  const slicedOptions = options.map(({ value }) => value).slice(0, maxShown);

  const [inputValue, setInputValue] = useState('');

  const [suggestions, dispatch] = useReducer(reducer, new Set<string>());
  const { setValue, getValues } = useFormContext();

  const handleSelectAll = (event: MouseEvent) => {
    event.preventDefault();
    let newInput: typeof optionValues;
    const suggArr = Array.from(suggestions);
    switch (getValues(name)?.length) {
      case 0:
        newInput = suggArr;
        break;
      default:
        newInput = [];
        break;
    }
    setValue(name, newInput);
    if (customOnChange) customOnChange(newInput);
  };

  const handleLoadMore = (event: MouseEvent) => {
    event.preventDefault();
    if (maxShown < optionValues.length) setMaxShown(maxShown + maxLoad);
  };

  const AutocompletePopper = ({ children, ...rest }: PopperProps) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { t } = useTranslation();

    return (
      <Popper {...rest}>
        <Paper>
          <div
            role="button"
            tabIndex={0}
            className={clsx('MuiPaper-root', 'MuiAutocomplete-paper', 'MuiPaper-elevation1')}
            onMouseDown={handleSelectAll}
          >
            <li className="MuiAutocomplete-option">
              <AutocompleteItem
                value={t('common.all')}
                label={t('common.all')}
                checked={getValues(name)?.length === optionValues?.length}
                indeterminate={getValues(name)?.length !== optionValues?.length && getValues(name)?.length !== 0}
              />
            </li>
          </div>
          {children as ReactNode}
          {maxOptions && maxShown < optionValues.length && (
            <div
              role="button"
              // eslint-disable-next-line jsx-a11y/tabindex-no-positive
              tabIndex={1}
              className={clsx('MuiPaper-root', 'MuiAutocomplete-paper', 'MuiPaper-elevation1')}
              onMouseDown={handleLoadMore}
            >
              <li className="MuiAutocomplete-option">Load more...</li>
            </div>
          )}
        </Paper>
      </Popper>
    );
  };

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, ...field } }): ReactElement => (
        <Autocomplete
          {...field}
          {...(multiple && {
            inputValue,
            onBlur: () => setInputValue(''),
            onKeyDown: (event) => {
              const suggArr = Array.from(suggestions);
              switch (event.key) {
                case 'a': // STRG + A should select all
                  if (event.ctrlKey || event.metaKey) {
                    onChange(suggArr);
                    if (customOnChange) customOnChange(suggArr);
                  }
                  break;
                default:
                  break;
              }
            },
            PopperComponent: AutocompletePopper,
          })}
          onChange={(_, value) => {
            if (customOnChange) {
              customOnChange(value);
            }
            onChange(value);
          }}
          onInputChange={() => dispatch({ type: 'reset' })}
          disableClearable={disableClearable}
          multiple={multiple}
          options={optionValues}
          limitTags={5}
          className={className}
          disableCloseOnSelect={multiple}
          getOptionLabel={(option: string) => getLabel(options, option)}
          noOptionsText={t('carsTable.noEntriesFound')}
          groupBy={groupBy}
          renderOption={(value, { selected }) => {
            if (!suggestions.has(value)) dispatch({ type: 'add', suggestion: value });
            if (!maxOptions)
              return (
                <AutocompleteItem
                  hideCheckbox={hideCheckbox}
                  checked={selected}
                  iconType={iconType}
                  value={value}
                  alt={label}
                  IconComponent={IconComponent}
                  label={getLabel(options, value)}
                />
              );
            if (slicedOptions.includes(value) || (suggestions.size !== optionValues.length && suggestions.has(value))) {
              return (
                <AutocompleteItem
                  hideCheckbox={hideCheckbox}
                  checked={selected}
                  iconType={iconType}
                  value={value}
                  alt={label}
                  IconComponent={IconComponent}
                  label={getLabel(options, value)}
                />
              );
            }

            return null;
          }}
          renderInput={(params): ReactElement => (
            <TextField
              {...params}
              {...(multiple && {
                onChange: (event) => {
                  setInputValue(event.currentTarget.value);
                },
              })}
              variant="outlined"
              label={label}
              margin="dense"
              className={classes.heightReduce}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                classes: {
                  notchedOutline: highlightTextField ? classes.notchedOutline : undefined,
                },
                endAdornment: (
                  <>
                    {isLoading && <CircularProgress color="secondary" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderTags={(tagValues, getTagProps) =>
            tagValues.map((option, index) => {
              const tagProps: any = getTagProps({ index });
              return (
                /* eslint-disable react/jsx-props-no-spreading */
                <Chip
                  {...tagProps}
                  size="small"
                  className={classes.chip}
                  onDelete={onChipDelete ? () => onChipDelete(option) : tagProps.onDelete}
                  label={getLabel(options, option)}
                  avatar={
                    iconType ? (
                      <Avatar src={getIcon(iconType, option)} imgProps={{ style: { transform: 'scale(1.1)' } }} />
                    ) : undefined
                  }
                />
              );
            })
          }
        />
      )}
    />
  );
};

export default FilterAutocomplete;
