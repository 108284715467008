import { Typography, makeStyles } from '@material-ui/core';
import { IndividualBidDTO } from '../modules/generated/api';
import { BidSuccessStatus } from '../modules/generated/api/api';

const useStyles = makeStyles(() => ({
  tableCell: {
    paddingLeft: '10px',
  },
}));

type CarsTableBoughtKVPSProps = {
  bids?: IndividualBidDTO[];
};

const CarsTableBoughtKVPS = ({ bids }: CarsTableBoughtKVPSProps) => {
  const classes = useStyles();

  const winningBid = bids?.find(
    (bidSubmitted) =>
      bidSubmitted.status === BidSuccessStatus.Highest || bidSubmitted.status === BidSuccessStatus.Bought,
  );

  return (
    <div className={classes.tableCell}>
      {winningBid &&
        <Typography variant="body2">
          {winningBid.kvps}
        </Typography>
      }
    </div>
  );
};

export default CarsTableBoughtKVPS;
